import React from "react";
import { Link } from "gatsby";

import "./index.scss";
// import "../../scss/main.scss";

const BtnMailto = ({ mail, label }) => {
	const handleMailto = (x) => {
		console.log(`mailto:${x}`);
		return `mailto:${x}`;
	};

	return (
		<Link
			to="#"
			onClick={(e) => {
				e.preventDefault();
				window.location = handleMailto(mail);
			}}
		>
			<button className="btn btn-mailto">{label}</button>
		</Link>
	);
};

export default BtnMailto;
