import React from "react";

import Layout from "../components/layout/index";
import Seo from "../components/seo";
import TeamCard from "../components/teamCard";
import "../scss/a-propos.scss";
import Aproposillustration from "../images/undraw_programmer.svg";
import EthicCircle from "../images/ethic-circle.svg";
import EthicGroup from "../images/ethic-group.svg";

const Apropos = () => {
	return (
		<Layout>
			<Seo title="À notre propos" />
			<div className="page-content">
				<section className="a-propos">
					<div className="a-propos-left">
						<h2 className="a-propos-left-title">À notre propos</h2>
						<p className="a-propos-paragraph">
							La CLI est une association de travailleuses et travailleurs du
							numériques basée à Strasbourg. Nous sommes sensibles à l'éthique du
							libre et à la qualité du travail.
						</p>
						<p className="a-propos-paragraph">
							Les enjeux majeurs contemporain sont en lien étroit avec le numérique.
							Il s'agit par exemple du respect des données privées, réduction de la
							fracture numérique, accessibilité, impact écologique. La CLI et ses
							membres à titre individuel s'engagent à en prendre conscience et à ainsi
							orienter leur manière de produire. Nous croyons que le croisement de nos
							démarches nous enrichit collectivement, notamment sur ces thématiques.
						</p>
						<p className="a-propos-paragraph">
							L'objectif de l'association est de fournir un cadre horizontal et
							convivial pour permettre l'émergence de prestations collectives et plus
							vertueuses, grâce à la libre association des unes avec les autres. Cette
							organisation permet la mise à l'échelle de nos moyens de production :
							important ou plus modeste, votre projet sera bien traité par nos
							équipes.
						</p>
					</div>
					<div className="a-propos-right">
						<img
							src={Aproposillustration}
							alt="illustration"
							className="a-propos-illustration"
						/>
					</div>
				</section>
				<section className="team">
					<h2 className="team-title">L'équipe</h2>
					<div className="team-card-group">
						<TeamCard
							name="Luc"
							mail="luc@cli.coop"
							description="SysAdmin et Sûreté"
						/>
						<TeamCard
							name="Justin"
							mail="justin@cli.coop"
							description="Développeur, formateur"
						/>
						<TeamCard
							name="Aleks"
							mail="alex@cli.coop"
							description="Développeur, formateur"
						/>
						<TeamCard name="Julien" mail="julien@cli.coop" description="Gestionnaire" />
					</div>
				</section>
				<section className="ethic">
					<div className="ethic-container">
						<img
							src={EthicCircle}
							className="ethic-circle"
							alt="Découvrez notre éthique"
						/>
						<img
							src={EthicGroup}
							className="ethic-group"
							alt="Découvrez notre éthique"
						/>
						<div className="ethic-paragraph">
							<p>professionnelle</p>
							<p>
								Nous nous occupons de l'ensemble du cycle de vie du projet numérique
								: de la conception, au développement, jusqu'à sa mise en production
							</p>
						</div>
						<div className="ethic-paragraph">
							<p>humaine</p>
							<p>
								Un besoin quelconque en informatique ? Nous sommes à vos côtés pour
								vous accompagner dans la démarche
							</p>
						</div>
						<div className="ethic-paragraph">
							<p>sécurité</p>
							<p>
								La sécurité par défaut : ça paraît normal, mais ça va mieux en le
								disant.
							</p>
						</div>
						<div className="ethic-paragraph">
							<p>technologique</p>
							<p>
								Notre credo est de créer des outils conviviaux, vous permettrant de
								gagner votre autonomie des GAFAM. La technologie est au service des
								personnes et non l'inverse.
							</p>
						</div>
					</div>
				</section>
			</div>
		</Layout>
	);
};

export default Apropos;
