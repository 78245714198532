import React from "react";

import "./index.scss";
import BtnMailto from "../btnMailto";

const TeamCard = ({ name, mail, description }) => {
	return (
		<div className="team-card">
			<div className="member-details">
				<h1 className="member-name">{name}</h1>
				<h2 className="member-description">{description}</h2>
			</div>
			<BtnMailto label="Lui ecrire" mail={mail} />
		</div>
	);
};

export default TeamCard;
